<template>
  <v-parallax
    dark
    height="600"    
    src="https://image.freepik.com/free-vector/bright-background-with-dots_1055-3132.jpg"
  >
    <v-layout align-center row>
      <v-flex xs12 sm12 md12>
        <v-btn rounded color="primary" class="headline" :href="reservation.guideUrl" target="_blank">Guidebook</v-btn>
      </v-flex>
    </v-layout>
  </v-parallax>
</template>

<script>
import store from '@/store'
import { CallDialog, Exceptions } from '@/utils'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  data: () => ({
      size: 250,
      level: 'H',
  }),
  mounted () {
    console.log('this.reservation', this.reservation)
  },
  computed: {
    ...mapState({
      reservation: state => state.checkIn.reservation
    }),
    ...mapGetters('checkIn', {
      allIdVerified: 'allIdVerified'
    })
  },
  methods: {
  },
  components: {
  }
}
</script>